@import './common';

.landing-acquisition-modal {
  max-width: 327px !important;
  min-height: 346px !important;
  height: auto !important;
  width: calc(100% - 32px) !important;

  .andes-modal-dialog__content {
    padding: 24px;
  }

  .acquisition-modal-img-content {
    height: 100px !important;
    margin-top: -8px;
  }

  .acquisition-modal-title {
    display: flex;
    font-size: $font-size-20;
    font-weight: $font-weight-semibold;
    text-align: center;
    color: $andes-gray-900;
  }

  .acquisition-modal-description {
    line-height: 1.25rem !important;

    .bullet-list {
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 22px;

      .bullet-item {
        padding-top: 3px;
        text-align: left;
      }
    }
  }
}

.andes-modal__overlay--small {
  padding: 3em 1.5em;
}
